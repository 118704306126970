<template>
  <div class="relative w-full flex flex-col gap-1">
    <!-- Cannot Render States -->
    <transition>
      <div v-if="usingAllTimeDateRange && !showLoadingOverlay" class="absolute left-1/2 top-32 transform -translate-x-1/2 z-50
      flex flex-col items-center gap-1.5 py-4 px-6 rounded-lg bg-neutral-800 shadow-md">
        <BaseText type="label" size="sm" class="text-white">
          No Date Range Selected
        </BaseText>
        <BaseText type="body" size="xs" class="text-neutral-alpha-700">
          Select a date range to generate a line graph
        </BaseText>
      </div>
    </transition>
    <div class="line-graph-container flex gap-1.5">
      <!-- Left Axis Labels -->
      <div class=" relative h-full flex flex-col pl-2 pr-1 pt-1.5" style="max-width: 100px; padding-bottom: 29px">
        <!-- Metric Name -->
        <div class="relative w-full h-6">
          <BaseText v-if="!usingAllTimeDateRange" type="label" size="xs" class="text-text-muted capitalize cursor-default absolute top-0 left-0 w-max">
            {{ getMetricLookup?.[selectedKpis[0]]?.name || selectedKpis[0] }}
          </BaseText>
        </div>
        <!-- Axis Labels -->
        <div class="flex-grow flex flex-col justify-between">
          <div v-for="(label, index) in [...leftAxisLabels].reverse()" :key="`left-axis-label-${index}`" class="fade-in">
            <BaseText type="body" size="xs" class="text-text-normal">
              {{ getIsPrivateMode ? '••' : label }}
            </BaseText>
          </div>
        </div>
      </div>
      <!-- Line Graph Area & Date Footer -->
      <div class="flex-grow flex flex-col pt-1.5">
        <div class="relative flex-grow w-full" style="padding-top: 32px">
          <!-- Loading Overlay -->
          <transition>
            <BaseLoadingSpinnerCircle v-if="showLoadingOverlay" :width="36" :height="36" :duration="1"
            class="text-text-normal absolute left-1/2 top-36 transform -translate-x-1/2 z-50" />
          </transition>
          <LineGraphRenderer
            :graph-data="graphData" 
            :selected-kpis="selectedKpis" 
            :axis-scales="axisScales"
            :date-segments="dateSegments"
            :raw-date-segments="rawDateSegments"
            :date-interval-days="dateIntervalDays"
            :uses-full-date-interval="usesFullDateInterval"
            :num-plot-lines="numPlotLines"
          />
        </div>
        <!-- Date Interval Footer -->
        <div class="w-full h-9 flex items-center justify-between" style="padding-left: 18px"
        :style="{ paddingRight: usesFullDateInterval ? '18px' : '66px' }">
          <div v-for="(date, index) in dateSegments" :key="`date-interval-${index}`" 
          class="relative w-px h-full fade-in">
            <BaseText type="body" size="xs" class="date-axis-label text-text-normal">
              {{ formatDateAxisLabel(date) }}
            </BaseText>
          </div>
        </div>
      </div>
      <!-- Right Axis Labels -->
      <div class="relative h-full flex flex-col pr-2 pl-1 pt-1.5 text-right" style="max-width: 100px; padding-bottom: 29px">
        <!-- Metric Name -->
        <div v-if="!usingAllTimeDateRange" class="relative w-full h-6">
          <BaseText type="label" size="xs" class="text-text-muted capitalize cursor-default absolute top-0 right-0 w-max">
            {{ getMetricLookup?.[selectedKpis[1]]?.name || selectedKpis[1] }}
          </BaseText>
        </div>
        <!-- Axis Labels -->
        <div class="flex-grow flex flex-col justify-between">
          <div v-for="(label, index) in [...rightAxisLabels].reverse()" :key="`right-axis-label-${index}`">
            <BaseText type="body" size="xs" class="text-text-normal">
              {{ getIsPrivateMode ? '••' : label }}
            </BaseText>
          </div>
        </div>
      </div>
    </div>
    <!-- Data Source Footer -->
    <div ref="dataSources" class="w-full flex items-center justify-center gap-2 px-2 py-1" style="height: 44px">
      <div v-for="(item, index) in dataItems" :key="`data-source-${item.group_by || item.ad_id}`" 
      class="data-source-item transition-colors duration-100 hover:bg-neutral-50 cursor-default fade-in"
      :style="{ '--outline-color': getItemLineColor(item?.group_by), minWidth: `${sourceMinWidths[index]}px` }"
      @mouseenter="hoveredDataSource = index" @mouseleave="hoveredDataSource = null">
        <img :src="item?.ads?.[0]?.ad_image_url || item?.ad_image_url" class="rounded-md object-cover flex-shrink-0">
        <BaseText type="body" size="xs" class="flex-shrink text-text-muted truncate pl-3 pr-1">
          {{ item.group_by || item.ad_name }}
        </BaseText>
        <!-- Footer Buttons -->
        <transition :name="isAdLevel ? 'buttons-ad-level' : 'buttons'">
          <div v-if="hoveredDataSource === index" class="footer-buttons ml-auto" :style="{'--button-width': isAdLevel ? '28px' : '56px'}">
            <button class="p-1" @mouseenter="hoveredCreativeInsights = index" @mouseleave="hoveredCreativeInsights = null">
              <AdDetailsIcon :is-hovered="hoveredCreativeInsights === index" />
            </button>
            <button v-if="!isAdLevel" class="p-1" @mouseenter="hoveredGroupDetails = index" @mouseleave="hoveredGroupDetails = null"
            @click="$emit('showGroupBreakdown', item)">
              <GroupDetailsIcon :is-hovered="hoveredGroupDetails === index" />
            </button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { scaleLinear } from 'd3-scale'
import LensAPI from '@/api/lens'
import formatLabelString from '../../../../utils/lens/formatGraphLabelString'

import LineGraphRenderer from './LineGraphRenderer.vue'

// Icons
import AdDetailsIcon from '../../../globals/Icons/AdDetailsAnimatedIcon.vue'
import GroupDetailsIcon from '../../../globals/Icons/LensIcons/GroupDetailsAnimatedIcon.vue'

export default {
  name: 'LensLineGraphs',
  components: {
    LineGraphRenderer,
    AdDetailsIcon,
    GroupDetailsIcon
  },
  props: {
    dataItems: {
      type: Array,
      default: () => []
    },
    dataSummary: {
      type: Object,
      default: () => {}
    },
    selectedKpis: {
      type: Array,
      default: () => []
    },
    isAdLevel: {
      type: Boolean,
      default: false
    },
    loadingData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // Graph States
      graphData: null,
      loadingTimeSeriesData: false,
      maxColValues: null,
      dateIntervalDays: 0,
      usingAllTimeDateRange: false,

      // Axis States
      axisScales: [],
      leftAxisLabels: [],
      rightAxisLabels: [],
      dateSegments: [],
      rawDateSegments: [],
      usesFullDateInterval: true,
      numPlotLines: 0,

      // Data Source States
      sourcesResizeObserver: null,
      sourceMinWidths: [],
      hoveredDataSource: null,
      hoveredCreativeInsights: null,
      hoveredGroupDetails: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeSourceMinWidths()
      this.sourcesResizeObserver = new ResizeObserver(() => {
        this.computeSourceMinWidths()
      })
      this.sourcesResizeObserver.observe(this.$refs.dataSources)
    })
  },
  beforeDestroy () {
    if (this.sourcesResizeObserver && this.$refs.dataSources) {
      this.sourcesResizeObserver.unobserve(this.$refs.dataSources)
      this.sourcesResizeObserver.disconnect()
      this.sourcesResizeObserver = null
    }
  },
  watch: {
    dataItems: {
      handler () {
        this.fetchTimeSeriesData()
        this.$nextTick(() => { this.computeSourceMinWidths() })
      },
      deep: true
    },
    selectedKpis: {
      handler () {
        this.fetchTimeSeriesData()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getMappedRowColor']),
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    ...mapGetters('MiscModule', ['getIsPrivateMode']),
    showLoadingOverlay () {
      return this.loadingData || this.loadingTimeSeriesData
    }
  },
  methods: {
    async fetchTimeSeriesData () {
      if (!this.dataItems?.length || !this.selectedKpis?.length) return
      if (!this.dataSummary?.startDate || !this.dataSummary?.endDate) {
        this.usingAllTimeDateRange = true
        this.resetState() // Reset state if user selects 'All Time' date range
        return
      }
      this.usingAllTimeDateRange = false

      // First, we need to compute the step amount between the dates in the interval
      const { startDate, endDate } = this.dataSummary
      const numDays = Math.ceil(Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24))
      const stepAmount = Math.ceil(numDays / 13) // Meaning we allow max 14 labels on the x-axis
      this.dateIntervalDays = stepAmount

      this.loadingTimeSeriesData = true
      try {
        const payload = {
          group: this.dataSummary.groupedBy,
          sort: this.dataSummary.sort,
          filters: this.dataSummary.filters,
          currentWindow: this.dataSummary.currentWindow,
          selectedColumns: this.selectedKpis,
          selectedRowKeys: this.dataItems.map(item => item.group_by || item.ad_id),
          startDate: this.dataSummary.startDate,
          endDate: this.dataSummary.endDate
        }
        const response = await LensAPI.TimeSeries.getGroupTimeSeriesData(this.$route.params.lensId, payload, stepAmount)

        // Extract the max values for each kpi
        this.maxColValues = { ...response.max_metrics }

        // Process the date segments
        const dateSegments = []
        const rawDateSegments = []
        let usesFullDateInterval = true
        for (let i = 0; i < response.date_segments.length; i++) {
          const dateStr = response.date_segments[i]
          const [year, month, day] = dateStr.split('-').map(Number)
          const date = new Date(year, month - 1, day)
          dateSegments.push(date)
          rawDateSegments.push(dateStr)
          if (i === response.date_segments.length - 1 && date.getDate() !== this.dataSummary.endDate.getDate()) {
            usesFullDateInterval = false
          }
        }
        this.dateSegments = dateSegments
        this.rawDateSegments = rawDateSegments
        this.usesFullDateInterval = usesFullDateInterval

        this.graphData = response.data

        // Initialize the axis information
        this.initializeAxisInformation()
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to fetch time series data. See console for details'
        })
      } finally {
        this.loadingTimeSeriesData = false
      }
    },
    initializeAxisInformation () {
      if (!this.dataItems.length || !this.selectedKpis.length) {
        this.axisScales = []
        this.leftAxisLabels = []
        this.rightAxisLabels = []
        return
      }
      this.computeAxisScales()
      this.computeLeftAxisLabels()
      this.computeRightAxisLabels()
    },
    computeAxisScales () {
      const scales = []
      if (this.dataItems.length) {
        this.selectedKpis.forEach(kpi => {
          scales.push(createKpiAxisScale(this.maxColValues[kpi] || 1))
        })
      }
      this.axisScales = scales
    },
    computeLeftAxisLabels () {
      const scale = this.axisScales[0]
      let ticks = scale.ticks(10)
      const reduceTicks = (ticks) => {
        // Ensures that the number of ticks is less than or equal to 10
        let tickValues = [...ticks]
        if (ticks.length % 2 === 0) {
          const newMax = ticks[ticks.length - 1] + ticks[1]
          tickValues.push(newMax)
          scale.domain([0, newMax])
        }
        while (tickValues.length > 10) {
          tickValues = tickValues.filter((_, index) => index % 2 === 0)
        }
        return tickValues
      }
      ticks = reduceTicks(ticks)
      this.leftAxisLabels = ticks.map(tick => this.formatAxisLabelString(tick, 0))
      this.numPlotLines = ticks.length
    },
    computeRightAxisLabels () {
      // We ensure the right axis has the same number of labels as the left axis
      // We use the scale to obtain a rounded max value, and then create a step size
      
      if (this.selectedKpis.length < 2) {
        this.rightAxisLabels = []
        return
      }
      const numLabels = this.leftAxisLabels.length
      const scale = this.axisScales[1]
      const [_, d1] = scale.domain()
      const maxValue = d1

      const step = maxValue / (numLabels - 1)
      const ticks = Array.from({ length: numLabels }, (_, index) => index * step)
      this.rightAxisLabels = ticks.map(tick => this.formatAxisLabelString(tick, 1))
    },
    formatAxisLabelString (value, selectedKpiIndex) {
      const kpi = this.selectedKpis[selectedKpiIndex]
      const type = this.getMetricLookup?.[kpi]?.type
      return formatLabelString(value, type)
    },
    getItemLineColor (key) {
      return this.getMappedRowColor['line'][key]
    },
    formatDateAxisLabel (date) {
      if (date.getFullYear() !== new Date().getFullYear()) {
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
      }
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    },
    computeSourceMinWidths () {
      if (!this.$refs.dataSources) return
      if (!this.dataItems.length) {
        this.sourceMinWidths = []
        return
      }
      const availableWidth = this.$refs.dataSources.getBoundingClientRect().width - 16 - 56 // -16px for padding, -56px for gaps
      const maximumMinWidth = Math.floor(availableWidth / this.dataItems.length)

      const minWidths = []
      for (const item of this.dataItems) {
        const compNode = document.createElement('span')
        compNode.classList.add('text-body-xs', 'w-max', 'whitespace-nowrap', 'opacity-0', 'pointer-events-none')
        compNode.textContent = item.group_by || item.ad_name
        document.body.appendChild(compNode)
        const minWidth = compNode.getBoundingClientRect().width + 48 // +48px for image, padding, and gap
        minWidths.push(Math.min(minWidth, maximumMinWidth))
        document.body.removeChild(compNode)
      }

      this.sourceMinWidths = minWidths
    },
    resetState () {
      this.graphData = null
      this.loadingTimeSeriesData = false
      this.maxColValues = null
      this.dateIntervalDays = 0
      this.axisScales = []
      this.leftAxisLabels = []
      this.rightAxisLabels = []
      this.dateSegments = []
      this.rawDateSegments = []
      this.usesFullDateInterval = true
      this.numPlotLines = 0
    }
  }
}

const createKpiAxisScale = (maxValue) => {
  const max = maxValue || 1
  const scale = scaleLinear()
    .domain([0, max])
    .nice()
  return scale
}
</script>

<style scoped>
.line-graph-container {
  height: 324px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
}

.right-metric-name-tooltip {
  position: absolute;
  bottom: calc(100% + 2px);
  right: 4px;
}
.left-metric-name-tooltip {
  position: absolute;
  bottom: calc(100% + 2px);
  left: 4px;
}

.data-source-item {
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-grow: 1;
  max-width: max-content;
  padding: 4px;
}
.data-source-item img {
  width: 28px;
  height: 28px;
  box-shadow: 0px 0px 0px 4px var(--outline-color);
  outline: 2px solid #F6F8FA;
}
.footer-buttons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-shrink: 0;
  width: var(--button-width, 56px);
}
.date-axis-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.buttons-enter-active, .buttons-ad-level-enter-active {
  transition: width 150ms ease-in-out, opacity 75ms ease-in-out 75ms;
}
.buttons-leave-active, .buttons-ad-level-leave-active {
  transition: width 150ms ease-in-out, opacity 75ms ease-in-out;
}
.buttons-enter-from, .buttons-enter, .buttons-leave-to,
.button-ad-level-enter-from, .buttons-ad-level-enter, .buttons-ad-level-leave-to {
  width: 0px;
  opacity: 0;
}
.buttons-enter-to, .buttons-leave-from {
  width: 56px;
  opacity: 1;
}
.buttons-ad-level-enter-to, .buttons-ad-level-leave-from {
  width: 28px;
  opacity: 1;
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}

.fade-in {
  animation: fadeIn 100ms ease-in-out forwards;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>