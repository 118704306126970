<template>
  <div
    v-if="header?.key"
    class="p-3 pr-1.5 h-10 flex items-center gap-x-1 justify-between group cell-header"
  >
    <!-- Active pin  -->
    <div class="flex items-center gap-1">
      <div
        v-if="isSelected && columnColor.length"
        class="w-2.5 h-2.5 rounded-sm"
        :style="{ backgroundColor: columnColor }"
      />
      <BaseText
        type="heading"
        size="overline"
        class="text-text-subdued text-left truncate flex-shrink uppercase"
        :class="{ 'text-text-muted': header.is_pinned, 'mr-3.5': !isSelected || !columnColor.length }"
      >
        {{ metricName }}
      </BaseText>
    </div>
    <div
      v-if="!disableActions"
      class="flex items-center column-actions"
    >
      <!-- Action to pin -->
      <button
        class="hover:bg-background-normal text-icon-disabled rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
        :class="{'text-icon-muted opacity-100' : header.is_pinned}"
        @click="pinColumn(header)"
      >
        <PinIcon
          :class-name="header.is_pinned ? 'text-icon-muted' : 'text-icon-disabled'"
        />
      </button>
      <!-- Sort -->
      <div
        v-if="!isMetadata"
        v-on-clickaway="() => { showSortDropdown = false }"
        class="relative"
      >
        <button
          ref="sortBtn"
          class="hover:bg-background-normal text-icon-disabled rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
          :class="{ 'text-icon-muted opacity-100': currentSort?.by === header.key || showSortDropdown, 'bg-background-normal' : showSortDropdown }"
          @click="toggleShowDropdown"
        >
          <SortColumnIcon
            :class="currentSort?.by === header.key || showSortDropdown ? 'text-icon-muted' : 'text-icon-disabled'"
            :style="{ transform: currentSort?.by === header.key && currentSort.order === 'asc' ? 'scale(1, -1)' : 'none' }"
          />
        </button>

        <!-- Portal to parent to break stacking context -->
        <portal to="sort-dropdown">
          <div
            v-if="showSortDropdown"
            class="p-1 fixed mt-1 border border-border-normal bg-white w-max shadow-md rounded-lg
            transition-opacity duration-100"
            :class="dropdownFade ? 'opacity-100' : 'opacity-0'"
            style="z-index: 10"
            :style="{ top: `${sortDropdownPosition.top}px`, right: `${sortDropdownPosition.right}px` }"
          >
            <!-- TODO @sam: add selected state (checkmark) & maybe show selected first - ask eren  -->
            <button
              class="flex items-center gap-2 p-1.5 hover:bg-background-normal rounded-md"
              :class="{'bg-background-normal': header.key === currentSort.by && currentSort.order === 'desc'}"
              @click="sortColumn(header, 'desc')"
            >
              <SortColumnIcon class="text-icon-muted" />
              <BaseText
                size="sm"
                class="text-text-muted"
              >
                Sort highest to lowest
              </BaseText>
            </button>
            <button
              class="flex items-center gap-2 mt-0.5 p-1.5 hover:bg-background-normal rounded-md"
              :class="{'bg-background-normal': header.key === currentSort.by && currentSort.order === 'asc'}"
              @click="sortColumn(header, 'asc')"
            >
              <SortColumnIcon
                class="text-icon-muted"
                :style="{ transform: 'scale(1, -1)' }"
              />
              <BaseText
                size="sm"
                class="text-text-muted"
              >
                Sort lowest to highest
              </BaseText>
            </button>
          </div>
        </portal>
      </div>
      <!-- Select -->
      <div
        v-if="!isMetadata"
        ref="colHeader"
        class="hover:bg-background-normal text-icon-disabled  rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100 visual-tooltip-trigger"
        :class="{ 'text-icon-muted opacity-100': isSelected, 'cursor-not-allowed': !canSelectCol && !isSelected }"
        @click="selectColumn(header)"
        @mouseenter="showTooltip"
        @mouseleave="hideTooltip"
      >
        <CheckmarkIcon
          :class="isSelected ? 'text-icon-muted' : 'text-icon-disabled'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'

import SortColumnIcon from '../../../globals/Icons/SortColumnIcon.vue'
import CheckmarkIcon from '../../../globals/Icons/CheckmarkIcon.vue'
import PinIcon from '../../../globals/Icons/SpyderV2Icons/PinIcon.vue'
export default {
  name: 'HeaderActionCell',
  components: {
    SortColumnIcon,
    CheckmarkIcon,
    PinIcon
  },
  mixins: [clickaway],
  props: {
    header: {
      type: Object,
      default: () => {}
    },
    isSelected: {
      type: Boolean,
      default: () => false
    },
    columnColor: {
      type: String,
      default: () => ''
    },
    currentSort: {
      type: Object,
      default: () => {}
    },
    disableActions: {
      type: Boolean,
      default: () => false
    },
    canSelectCol: {
      type: Boolean,
      default: () => true
    },
    isAdLevel: {
      type: Boolean,
      default: () => false
    },
    isMetadata: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      showSortDropdown: false,
      sortDropdownPosition: {
        top: 0,
        right: 0
      },
      dropdownFade: false
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    metricName () {
      if (!this.header?.key) return null
      if (this.header.name) return this.header.name // For ad level metadata cols

      const name = this.getMetricLookup?.[this.header.key]?.name
      if (!name) return this.header.key
      // If there are parentheses, return the name without them
      if (name.includes('(')) {
        return name.split('(')[0].trim()
      }
      return name
    }
  },
  methods: {
    showTooltip () {
      const reference = this.$refs?.colHeader

      // Contains all the tooltips for column headers
      const tooltipContainer = this.$parent.$refs?.tooltipContainer
      const includeTooltip = tooltipContainer?.$refs?.includeTooltip
      if (includeTooltip) includeTooltip.style.display = 'block'

      if (includeTooltip && reference) {
        const tooltipRect = includeTooltip.getBoundingClientRect()
        const referenceRect = reference.getBoundingClientRect()
        // Calculate positions with offsets (in drawers, we need to offset the fixed position)
        const offset = this.isAdLevel ? { top: -80, left: -12 } : { top: 0, left: 0 }
        const position = {
          top: referenceRect.top - tooltipRect.height - 12 + offset.top,
          left: referenceRect.left + (referenceRect.width / 2) - (tooltipRect.width / 2) + offset.left
        }
        includeTooltip.style.top = `${position.top}px`
        includeTooltip.style.left = `${position.left}px`
        includeTooltip.style.opacity = '1'
        includeTooltip.style.transition = 'opacity 150ms ease'
      }
    },
    hideTooltip () {
      const tooltipContainer = this.$parent.$refs?.tooltipContainer
      const includeTooltip = tooltipContainer?.$refs.includeTooltip
      if (includeTooltip) {
        includeTooltip.style.opacity = '0'
        includeTooltip.style.transition = 'opacity 150ms ease'
      }
    },
    toggleShowDropdown () {
      // If enabling, set position
      if (!this.showSortDropdown) {
        const reference = this.$refs.sortBtn
        const referenceRect = reference.getBoundingClientRect()
        // Calculate positions with offsets (in drawers, we need to offset the fixed position)
        const offset = this.isAdLevel ? { top: -80, right: -12 } : { top: 0, right: 0 }
        this.sortDropdownPosition = {
          top: referenceRect.bottom + offset.top,
          right: window.innerWidth - referenceRect.right + offset.right
        }
        this.showSortDropdown = true
        // Have to do these fucked opacity anims since we cant use <transition>
        setTimeout(() => { this.dropdownFade = true }, 0)
      } else {
        this.dropdownFade = false
        setTimeout(() => { this.showSortDropdown = false }, 100)
      }
    },
    selectColumn (header) {
      if (!this.canSelectCol && !this.isSelected) return
      this.$emit('select', header)
    },
    pinColumn (header) {
      this.$emit('pin', header)
    },
    sortColumn (header, dir) {
      this.$emit('sort', header, dir)
      this.showSortDropdown = false
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: all 150ms;
}
.fade-enter, .fade-enter-to, .fade-leave-from {
  opacity: 1;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
