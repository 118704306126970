<template>
  <div class="grid-card flex flex-col p-0.5">
    <!-- Card Header -->
    <div class="w-full flex items-center px-2.5 py-1.5">
      <!-- Group By Label -->
      <div class="relative flex-grow flex items-center gap-2 pl-1 min-w-0">
        <BaseText type="label" size="sm" class="flex-shrink text-text-muted truncate cursor-default"
        @mouseenter.native="cardLabelHovered = true" @mouseleave.native="cardLabelHovered = false">
          {{ data.group_by || data.ad_name }}
        </BaseText>
        <!-- Ad Count Indicator -->
        <BaseText v-if="!isAdLevel" type="label" size="xs" class="px-1.5 py-0.5 bg-neutral-50 text-text-muted" style="border-radius: 4px">
          {{ data?.ads?.length || 0 }}
        </BaseText>
        <!-- Label hover tooltip -->
        <transition>
          <div v-if="cardLabelHovered" class="label-hover-tooltip px-1.5 py-1 bg-neutral-800 rounded-md cursor-default shadow-sm">
            <BaseText type="label" size="xs" class="text-white">
              {{ data.group_by || data.ad_name }}
            </BaseText>
          </div>
        </transition>
      </div>
      <!-- Group Action Buttons -->
      <div class="ml-auto pl-1 flex items-center" v-if="!isPublic">
        <button class="p-1" @mouseenter="creativeInsightsHovered = true" @mouseleave="creativeInsightsHovered = false">
          <AdDetailsAnimatedIcon :is-hovered="creativeInsightsHovered" />
        </button>
        <button v-if="!isAdLevel" class="p-1" @mouseenter="groupDetailsHovered = true" @mouseleave="groupDetailsHovered = false"
        @click="$emit('showGroupBreakdown')">
          <GroupDetailsIcon :is-hovered="groupDetailsHovered" />
        </button>
      </div>
    </div>
    <!-- Group Media Previews -->
    <div class="w-full flex flex-col p-0.5 bg-neutral-25 rounded-lg">
      <!-- Selected Preview Media Viewer -->
      <div class="cell-media-container rounded-md overflow-hidden">
        <!-- Blurred Background Image -->
        <div class="blur-image-background" :style="{backgroundImage: `url(${selectedAdPreview.ad_image_url})`}" />
        <!-- Media Element -->
        <BaseAdvertisementVideoPlayer v-if="adPreviewType === 'video' && adPreviewVideoData"
          :advertisement="adPreviewVideoData"
          contain-media
          class="video-element"
          @loaded-thumbnail="loadingSelectedAdPreview = false"
          @loaded-content="loadingSelectedAdPreview = false"
        />
        <img v-else
          :src="selectedAdPreview.ad_image_url" 
          class="media-element object-contain" 
          @load="loadingSelectedAdPreview = false" 
        />
        <!-- Loading Spinner -->
        <transition name="loading">
          <div v-if="showLoadingSpinner" class="loading-overlay">
            <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 shadow-lg p-1.5 rounded-lg"
            style="background-color: rgba(0, 0, 0, 0.35); backdrop-filter: blur(4px); opacity: 0.75">
              <BaseLoadingSpinner />
            </div>
          </div>
        </transition>
      </div>
      <!-- Preview Selector -->
      <div v-if="!isAdLevel" ref="previewSelector" class="w-full flex items-center gap-1.5 px-1.5 pt-1.5 pb-1">
        <!-- Thumbnail Buttons -->
        <AssetPreviewItem 
          v-for="(adPreview, index) in visibleAdPreviews" :key="`ad-preview-${index}`"
          class="flex-shrink-0"
          :asset="adPreview"
          :size="adPreviewSize"
          :isSelected="selectedPreviewIndex === index"
          :doAnimate="doAnimatePreviewSelection"
          @selected="() => {changePreviewSelection(index)}"
        />
        <!-- Hidden Preview Counter -->
        <div v-if="visibleAdPreviews.length < data.ads.length" class="ml-auto">
          <BaseText type="label" size="xs" class="text-text-muted p-0.5">
            +{{ data.ads.length - visibleAdPreviews.length }}
          </BaseText>
        </div>
      </div>
    </div>
    <!-- Group Breakdown Info -->
    <div class="w-full flex flex-col gap-1 px-1 py-1.5">
      <!-- Group Media Type -->
      <div class="w-full flex items-center justify-between px-1.5">
        <BaseText type="body" size="sm" class="text-text-normal">
          Type
        </BaseText>
        <div class="flex items-center gap-1 px-1.5 py-1">
          <component :is="groupMediaType.icon" :width="18" :height="18" stroke="#5E6678" />
          <BaseText type="label" size="sm" class="text-text-muted">
            {{ groupMediaType.name }}
          </BaseText>
        </div>
      </div>
      <!-- Selected Metric Values -->
      <div v-for="(kpi, index) in selectedKpis" :key="`${data.group_by || data.ad_id}-metric-${index}`" 
      class="w-full flex items-center justify-between px-1.5">
        <BaseText type="body" size="sm" class="text-text-normal">
          {{ getMetricName(kpi) }}
        </BaseText>
        <BaseText type="label" size="sm" class="px-1.5 py-0.5 rounded-md"
        :style="{backgroundColor: getMetricScoreColor(kpi).background, color: getMetricScoreColor(kpi).text}">
          {{ getIsPrivateMode ? '••••' : formatMetricValueString(data?.[kpi], kpi) }}
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatLabelString from '../../../../utils/lens/formatGraphLabelString'
import { getRelativeMetricScoreColor } from '../../../../utils/lens/metricScoreUtil'
import AssetPreviewItem from './AssetPreviewItem.vue'

import AdDetailsAnimatedIcon from '../../../globals/Icons/AdDetailsAnimatedIcon.vue'
import GroupDetailsIcon from '../../../globals/Icons/LensIcons/GroupDetailsAnimatedIcon.vue'
import FormatIcon from '../../../globals/Icons/FilterIcons/FormatIcon.vue'
import ImageFormatIcon from '../../../globals/Icons/FilterIcons/ImageFormatIcon.vue'
import VideoFormatIcon from '../../../globals/Icons/FilterIcons/VideoFormatIcon.vue'

export default {
  name: 'GridGraphCard',
  components: {
    AssetPreviewItem,
    AdDetailsAnimatedIcon,
    GroupDetailsIcon
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    dataSummary: {
      type: Object,
      default: () => {}
    },
    selectedKpis: {
      type: Array,
      default: () => []
    },
    isAdLevel: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedPreviewIndex: 0,
      visibleAdPreviews: [],

      // UI State
      creativeInsightsHovered: false,
      groupDetailsHovered: false,
      doAnimatePreviewSelection: false,
      cardLabelHovered: false,
      adPreviewSize: 32, // px - Maybe make this sizing customizable?
      resizeObserver: null,
      loadingSelectedAdPreview: true,
      loaderThresholdCrossed: false
    }
  },
  watch: {
    selectedPreviewIndex (oldVal, newVal) {
      if (oldVal.ad_image_url === newVal.ad_image_url) return
      this.loadingSelectedAdPreview = true
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    ...mapGetters('LensModule', ['getColorFormat']),
    ...mapGetters('MiscModule', ['getIsPrivateMode']),
    selectedAdPreview () {
      if (this.isAdLevel) return this.data
      return this.data?.ads?.[this.selectedPreviewIndex]
    },
    adPreviewType () {
      if (this.selectedAdPreview.video_url_source) return 'video'
      return 'image'
    },
    adPreviewVideoData () {
      const { ad_id, ad_image_url, video_url_source } = this.selectedAdPreview
      if (!ad_id || !video_url_source) return null
      return {
        id: ad_id,
        ...(ad_image_url ? { thumbnail: ad_image_url } : {}),
        video: video_url_source
      }
    },
    groupMediaType () {
      // Determine if all video urls are null, all are not null, or a mix of both
      if (this.isAdLevel) {
        if (this.data.video_url_source) return { name: 'Video', icon: VideoFormatIcon }
        return { name: 'Image', icon: ImageFormatIcon }
      }
      const numVideoAds = this.data?.ads?.filter(ad => ad.video_url_source).length
      if (numVideoAds === this.data?.ads?.length) return { name: 'Video', icon: VideoFormatIcon }
      if (numVideoAds === 0) return { name: 'Image', icon: ImageFormatIcon }
      return { name: 'Multiple', icon: FormatIcon }
    },
    showLoadingSpinner () {
      return this.loaderThresholdCrossed && this.loadingSelectedAdPreview
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.isAdLevel) {
        this.computeVisibleAdPreviews()
        // Initialize a resize observer to watch the preview selector
        this.resizeObserver = new ResizeObserver(() => {
          this.computeVisibleAdPreviews()
        })
        this.resizeObserver.observe(this.$refs.previewSelector)
      }

      // Set a timer to display the loading spinner after a threshold of time has passed
      setTimeout(() => { this.loaderThresholdCrossed = true }, 300)
    })
  },
  beforeDestroy () {
    if (this.resizeObserver && this.$refs.previewSelector) {
      this.resizeObserver.unobserve(this.$refs.previewSelector)
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  },
  methods: {
    changePreviewSelection (index) {
      if (!this.doAnimatePreviewSelection) this.doAnimatePreviewSelection = true
      this.selectedPreviewIndex = index
    },
    getMetricName (kpi) {
      return this.getMetricLookup?.[kpi]?.name || kpi
    },
    formatMetricValueString (value, kpi) {
      const type = this.getMetricLookup?.[kpi]?.type
      return formatLabelString(value, type)
    },
    computeVisibleAdPreviews () {
      if (this.isAdLevel || !this.data?.ads || !this.data.ads.length || !this.$refs.previewSelector) return

      const LIST_GAP = 6 // px
      const computeListWidth = (numAds) => {
        return numAds * this.adPreviewSize + (numAds - 1) * LIST_GAP
      }

      // Compute the number of visible previews
      const availableWidth = this.$refs.previewSelector.getBoundingClientRect().width - 12 // -12px for padding
      const numAds = this.data.ads.length
      const previewListWidth = computeListWidth(numAds)
      if (previewListWidth <= availableWidth) {
        this.visibleAdPreviews = this.data.ads.map(ad => ad.ad_image_url)
      } else {
        let numVisiblePreviews = Math.floor((availableWidth - LIST_GAP) / (this.adPreviewSize + LIST_GAP))

        // Compute the rendered size of the hidden preview counter and adjust the number of visible previews if necessary
        const compNode = document.createElement('span')
        compNode.classList.add('p-0.5', 'text-label-xs', 'opacity-0', 'pointer-events-none')
        compNode.textContent = `+${numAds - numVisiblePreviews}`
        document.body.appendChild(compNode)
        const counterWidth = compNode.getBoundingClientRect().width + LIST_GAP
        document.body.removeChild(compNode)
        if (computeListWidth(numVisiblePreviews) + counterWidth > availableWidth) numVisiblePreviews--
        
        this.visibleAdPreviews = this.data.ads.slice(0, numVisiblePreviews).map(ad => ad.ad_image_url)
      }
    },
    getMetricScoreColor (kpi) {
      const NEUTRAL_COLOR = { background: '#F6F8FA', text: '#303546' }
      const EXCLUDED_KPIS = ['spend']
      if (EXCLUDED_KPIS.includes(kpi)) return NEUTRAL_COLOR

      const percentDiff = Math.floor(this.data.percent_deviation?.[kpi])
      const colorInfo = getRelativeMetricScoreColor(percentDiff, kpi, this.getColorFormat, this.dataSummary)
      if (!colorInfo.scoreColor.length) return NEUTRAL_COLOR
      
      const textColor = colorInfo.direction === 'positive' ? '#005043' : colorInfo.direction === 'negative' ? '#7C001F' : '#303546'
      return { background: colorInfo.scoreColor, text: textColor }
    }
  }
}
</script>

<style scoped>
.grid-card {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
}
.cell-media-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
}
.blur-image-background {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(0px);
}
.blur-image-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(235, 239, 243, 0.25);
  backdrop-filter: blur(12px);
}
.media-element {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}
.video-element {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.label-hover-tooltip {
  position: absolute;
  bottom: calc(100% + 4px);
  left: -4px;
  max-width: 200px;
  z-index: 40001; /* So it's above the filter toolbar */
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 21, 32, 0.1);
  z-index: 50;
}

.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}

.loading-enter-active, .loading-leave-active {
  transition: opacity 100ms ease-in-out;
}
.loading-enter-from, .loading-enter, .loading-leave-to {
  opacity: 0;
}
.loading-enter-to, .loading-leave-from {
  opacity: 1;
}
</style>