<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2499 14.7502L16.25 5.25002C16.2501 4.42158 15.5785 3.75 14.75 3.75H5.25006C4.42163 3.75 3.75006 4.42157 3.75006 5.24999L3.75001 14.7501C3.75 15.5786 4.42158 16.2501 5.25001 16.2501H14.7499C15.5784 16.2501 16.2499 15.5786 16.2499 14.7502Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M7 13V10.7501M10.0001 13V7M13 13V9.25014"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ReportIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

  <style scoped></style>
