<template>
  <lottie
    :options="defaultOptions"
    :width="width"
    :height="height"
    @animCreated="handleAnimation"
  />
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../../assets/lotties/groupDetailsDrawer.json'

export default {
  name: 'GroupDetailsAnimatedIcon',
  components: {
    lottie: Lottie
  },
  props: {
    isHovered: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  },
  data () {
    return {
      defaultOptions: {
        animationData: animationData,
        loop: false,
        autoplay: false
      }
    }
  },
  watch: {
    isHovered (val) {
      if (val) {
        this.anim.setDirection(1);
        this.anim.play();
      } else {
        this.anim.setDirection(-1);
        this.anim.play();
      }
    }
  },
  methods: {
    handleAnimation (anim) {
      this.anim = anim;
      this.anim.setSpeed(1.5);
    }
  }
}
</script>

<style scoped></style>
