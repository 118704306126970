<template>
  <button class="preview-item rounded-md overflow-hidden isolate" :style="{width: `${size}px`, height: `${size}px`}"
  :class="{'selected': showSelected}" @click="$emit('selected')">
    <img :src="asset" class="w-full h-full object-cover" @load="loadingImage = false">
    <!-- Image loading state -->
    <div v-if="loadingImage" class="absolute left-0 right-0 top-0 bottom-0 rounded-md bg-neutral-800 opacity-40">
      <BaseLoadingSpinnerCircle class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white" :duration="1" />
    </div>
    <!-- Selected indicators -->
    <transition :name="doAnimate ? 'overlay' : ''">
      <div v-if="showSelected" class="selected-overlay" />
    </transition>
    <transition :name="doAnimate ? 'checkmark' : ''">
      <DCOPreviewCheckmark v-if="showSelected" class="selected-checkmark" :width="20" :height="20" />
    </transition>
  </button>
</template>

<script>
import DCOPreviewCheckmark from '../../../advertisements/DetailsDrawerComponents/DCOPreviewCheckmark.vue'
export default {
  name: 'AssetPreviewItem',
  components: {
    DCOPreviewCheckmark
  },
  props: {
    asset: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    doAnimate: {
      type: Boolean,
      required: true
    },
    size: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      loadingImage: true
    }
  },
  computed: {
    showSelected () {
      return !this.loadingImage && this.isSelected
    }
  },
  watch: {
    asset () {
      this.loadingImage = true
    }
  }
}
</script>

<style scoped>
.preview-item {
  position: relative;
  transform: scale(1);
  transform-origin: center;
  transition: transform 100ms ease-in-out;
}
.preview-item:hover {
  transform: scale(0.96);
}
.selected-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  z-index: 10;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.44);
}
.selected-checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

/* Vue <transition> classes */
  .checkmark-enter-active, .checkmark-leave-active {
  transition: opacity 150ms ease-in-out;
}
.checkmark-enter-from, .checkmark-enter, .checkmark-leave-to {
  opacity: 0;
}
.checkmark-enter-to, .checkmark-leave-from {
  opacity: 1;
}
.overlay-enter-active, .overlay-leave-active {
  transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
}
.overlay-enter-from, .overlay-enter, .overlay-leave-to {
  opacity: 0;
  transform: scale(0);
}
.overlay-enter-to, .overlay-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>