import store from '../../store'

const getMetricLookup = store.getters['MetricsModule/getMetricLookup']
const positiveCells = ['#EFFEFA', '#D8F3EE', '#B9E9E0']
const negativeCells = ['#FFF0F3', '#FDD9E0', '#F9C8D2']
const ignoredCols = new Set(['status', 'spend', 'tags'])
const getRelativeMetricScoreColor = (percentDiff, kpi, outlierFormat, summaryStats) => {
  const noFormat = { scoreColor: '', direction: '' }
  const { max, min } = summaryStats.ad_groups?.percent_deviation

  const highestAvg = Math.floor(max[kpi])
  const lowestAvg = Math.floor(min[kpi])
  const metricDir = getMetricLookup[kpi]?.metric_direction
  const absPercentDiff = Math.abs(percentDiff)

  if (ignoredCols.has(kpi) || !metricDir || absPercentDiff < 5 || outlierFormat === 'none' || isNaN(absPercentDiff)) return noFormat

  const avgToDivide = metricDir === 'positive' ? highestAvg : lowestAvg

  const isGreen = metricDir === 'positive' ? percentDiff >= 5 : percentDiff < -5
  const cellColors = isGreen ? positiveCells : negativeCells
  const divider = Math.abs(Math.floor(avgToDivide / cellColors.length))
  const interval = Math.floor(absPercentDiff / divider)

  const scoreColor = cellColors[Math.min(interval, cellColors.length - 1)]
  const direction = (outlierFormat === 'positive' || outlierFormat === 'positive_negative') && isGreen ? 'positive' : 'negative'

  if ((outlierFormat === 'positive_negative') ||
      (isGreen && outlierFormat === 'positive') ||
      (!isGreen && outlierFormat === 'negative')) {
    return { scoreColor, direction }
  }

  return noFormat
}

export {
  getRelativeMetricScoreColor
}
