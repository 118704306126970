<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 5.5V10.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M5.75 5.5V10.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'PauseIcon',
  props: {
    width: {
      type: Number,
      default: 8
    },
    height: {
      type: Number,
      default: 16
    }
  }
}
</script>

<style scoped></style>
