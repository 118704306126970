import { format } from 'd3-format'

export default function formatLabelString (value, type) {
  switch (type) {
    case 'currency':
      if (Math.abs(value) >= 1) return format('$.3~s')(value)
      return `$${format('.2~')(value)}`
    case 'percent':
      return `${format('.2~')(value)}%`
    case 'number':
      if (value < 1) return format('.2~r')(value)
      return format('.3~s')(value)
    default:
      return `${value}`
  }
}