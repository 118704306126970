<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M7.97461 9.32461V12.6996M11.3496 5.27461V12.6996M14.7246 10.6746V12.6996M4.59961 4.59961V14.0496C4.59961 14.7952 5.20402 15.3996 5.94961 15.3996H16.0746" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BarGraphIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
