<template>
  <div class="relative" :class="{ 'w-max': !isMetadata }">
    <BaseText size="sm" class="relative table-cell-value"
    :class="{ 'truncate': isMetadata, [getTextColor()]: true }"
    >
      <a v-if="isUrl && cellValue.length" :href="cellValue" target="_blank" class="underline">
        {{ formatValue(cellValue) }}
      </a>
      <span v-else>
        {{ getIsPrivateMode && !isMetadata ? '•••••••••' : formatValue(cellValue) }}
      </span>
    </BaseText>
    <!-- Tooltip -->
    <div v-if="avgDiff !== undefined && (cellValue !== undefined || cellValue !== null)"
    class="transform px-1.5 py-1 rounded-md absolute tooltip-container w-max">
      <BaseText size="xs" class="flex items-center gap-1">
        <span :class="getDiffClass()">{{ getFormatAvg() }}</span><span class="text-neutral-alpha-800">compared to the avg. </span>
      </BaseText>
      <!-- Arrow -->
      <svg x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"
      class="absolute h-2 w-full left-0" style="color: #24252D; top: calc(100% - 1px)" >
        <polygon class="fill-current" points="0,0 127.5,127.5 255,0"/>
      </svg>
    </div>
    <!-- Metadata tooltip -->
    <div v-if="isMetadata && cellValue !== undefined && cellValue.length"
    class="transform px-1.5 py-1 rounded-md absolute tooltip-container w-max">
      <BaseText size="xs" class="metadata-tooltip flex items-center gap-1 text-neutral-alpha-800">
        {{ getIsPrivateMode ? '•••••••••••••' : cellValue }}
      </BaseText>
      <!-- Arrow -->
      <svg x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"
      class="absolute h-2 w-full left-0" style="color: #24252D; top: calc(100% - 1px)" >
        <polygon class="fill-current" points="0,0 127.5,127.5 255,0"/>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TableCell',
  props: {
    colorFormat: {
      type: String,
      default: () => ''
    },
    cellType: {
      type: String
    },
    cellValue: {
      type: [String, Number]
    },
    avgDiff: {
      type: [Number, undefined],
      default: () => undefined
    },
    metricDirection: {
      type: String,
      default: () => 'positive'
    },
    isMetadata: {
      type: Boolean,
      default: () => false
    },
    isUrl: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getIsPrivateMode'])
  },
  methods: {
    getTextColor () {
      if (!this.colorFormat || !this.avgDiff || this.cellValue === 0) return 'text-text-muted';
      return this.colorFormat === 'positive' ? 'text-secondary-green-300' : 'text-secondary-red-300';
    },
    getFormatAvg () {
      const value = this.avgDiff
      const prefix = this.avgDiff < 0 ? '' : '+'

      return `${prefix}${Math.floor(value)}%`
    },
    getDiffClass () {
      const value = Math.floor(this.avgDiff)

      const isPositive = this.metricDirection === 'positive'
      const isGreen = isPositive ? value > 0 : value < 0
      const colorClass = isGreen ? 'text-green-500' : 'text-red-500'

      return value === 0 ? 'text-white' : colorClass
    },
    formatValue () {
      if (!this.cellValue && this.cellValue !== 0) return '-'

      if (this.cellType === 'text') {
        return this.cellValue
      } else if (this.cellType === 'currency') {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(this.cellValue)
      } else if (this.cellType === 'percent') {
        return `${this.cellValue?.toFixed(2)}%`
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          maximumFractionDigits: 2
        }).format(this.cellValue)
      }
    }
  }
}
</script>

<style scoped>
.tooltip-container{
  background: #24252D;
  bottom: calc(100% - 18px);
  opacity: 0;
  z-index: 999 !important;
  transition: all 150ms;
  pointer-events:none;

  left: 50%;
  transform: translateX(-50%) translateY(0);
}

.table-cell-value:hover + .tooltip-container {
  transform: translateX(-50%) translateY(-12px) !important;
  opacity: 1 !important;
}

.metadata-tooltip {
  max-width: 800px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
</style>
