<template>
  <div class="grid-graph-container w-full" style="min-height: 360px">
    <div v-for="item in dataItems" :key="`grid-card-${item.group_by || item.ad_id}`">
      <div class="flex-grow mx-auto h-full" style="max-width: 410px">
        <GridGraphCard
          :data="item"
          :data-summary="dataSummary"
          :selected-kpis="selectedKpis"
          :is-ad-level="isAdLevel"
          :is-public="isPublic"
          @showGroupBreakdown="$emit('showGroupBreakdown', item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GridGraphCard from './GridGraphCard.vue'
export default {
  name: 'LensGridGraphs',
  components: {
    GridGraphCard
  },
  props: {
    dataItems: {
      type: Array,
      default: () => []
    },
    dataSummary: {
      type: Object,
      default: () => {}
    },
    selectedKpis: {
      type: Array,
      default: () => []
    },
    isAdLevel: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.grid-graph-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 4px;
}
</style>
