<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99999 10.0015V12.7013M16.075 10.0013C16.075 13.3564 13.3551 16.0763 9.99999 16.0763C6.64486 16.0763 3.92499 13.3564 3.92499 10.0013C3.92499 6.64614 6.64486 3.92627 9.99999 3.92627C13.3551 3.92627 16.075 6.64614 16.075 10.0013Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 6.79541C9.72044 6.79541 9.49377 7.02207 9.49377 7.30166C9.49377 7.58125 9.72044 7.80791 10 7.80791C10.2796 7.80791 10.5063 7.58125 10.5063 7.30166C10.5063 7.02207 10.2796 6.79541 10 6.79541Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: 'InformationIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

  <style scoped></style>
