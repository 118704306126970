<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M3.9248 5.27539V13.3754C3.9248 14.121 4.52922 14.7254 5.2748 14.7254H16.0748M6.6248 12.0254L8.6498 10.0004C9.02261 9.62759 9.627 9.62759 9.9998 10.0004C10.3726 10.3732 10.977 10.3732 11.3498 10.0004L14.365 6.98525M12.0248 6.62539H14.7248V9.32539" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'LineGraphIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
