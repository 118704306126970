<template>
  <svg :width="dimensions.width" :height="dimensions.height" :viewBox="`0 0 ${dimensions.width} ${dimensions.height}`">
    <path 
      :d="lineData?.path || ''" 
      :stroke="lineColor" 
      :stroke-width="2" 
      fill="none" 
      :stroke-dasharray="isPrimaryMetric ? 'none' : lineDashArray"
      stroke-linejoin="round" 
      stroke-linecap="round" 
    />
  </svg>
</template>

<script>
// Line Rendering Settings
const MAX_VERTEX_ROUNDING_RADIUS = 16
const MIN_VERTEX_ROUNDING_RADIUS = 3
const LINE_DASH_LENGTH = 6
const LINE_DASH_GAP = 8

export default {
  name: 'DynamicLine',
  props: {
    dimensions: {
      type: Object,
      required: true
    },
    coordinates: {
      type: Array,
      required: true
    },
    isPrimaryMetric: {
      type: Boolean,
      default: true
    },
    dataIndex: {
      type: Number,
      required: true
    },
    lineColor: {
      type: String,
      default: '#00000033'
    }
  },
  watch: {
    lineData (newVal) {
      if (this.isPrimaryMetric && newVal?.pointMarkerOffsets?.length) {
        this.$emit('apply-marker-offsets', newVal.pointMarkerOffsets)
      }
    }
  },
  computed: {
    lineData () {
      const coords = this.coordinates
      if (coords.length < 2) return { path: '', pointMarkerOffsets: [] }
      if (coords.length === 2) {
        return { path: `M ${coords[0].x} ${coords[0].y} L ${coords[1].x} ${coords[1].y}`, pointMarkerOffsets: [] }
      }
      
      let path = `M ${coords[0].x} ${coords[0].y}` // Render the initial point

      const pointMarkerOffsets = [] // Store the point marker offsets for primary metric lines

      // Loop over each join point (skip first and last)
      for (let i = 1; i < coords.length - 1; i++) {
        const A = coords[i - 1]
        const B = coords[i]
        const C = coords[i + 1]
        
        // Vectors for segments AB and BC
        const v1 = { x: B.x - A.x, y: B.y - A.y }
        const v2 = { x: C.x - B.x, y: C.y - B.y }
        const norm1 = Math.hypot(v1.x, v1.y)
        const norm2 = Math.hypot(v2.x, v2.y)
        
        // Compute the angle at B
        const dot = v1.x * v2.x + v1.y * v2.y
        const theta = Math.acos(dot / (norm1 * norm2)) // Turning angle
        
        // Scale the fillet radius based on the angle (use a clamped theta value)
        const normalizedTheta = Math.min(theta, Math.PI / 2) / (Math.PI / 2)
        const r = MAX_VERTEX_ROUNDING_RADIUS - normalizedTheta * (MAX_VERTEX_ROUNDING_RADIUS - MIN_VERTEX_ROUNDING_RADIUS);
        // Determine the distance from B along the intersecting vectors where we allow the arc to render
        const d = r * Math.tan(theta / 2)

        // Determine the points where the arc starts and ends
        const { P, Q } = calculateArcStartAndEndPoints(B, v1, v2, d)

        // Compute the cross product of v1 and v2 to determine whether to render the arc on top or bottom
        const cross = v1.x * v2.y - v1.y * v2.x
        const sweepFlag = cross < 0 ? 0 : 1

        path += ` L ${P.x} ${P.y}`
        path += ` A ${r} ${r} 0 0 ${sweepFlag} ${Q.x} ${Q.y}`

        // Emit a point marker offset value depending on the intersection's turning angle (harsh angles will have offsets)
        if (this.isPrimaryMetric && B.dateIndex && theta > 0.6) {
          const offset = cross < 0 ? -1 : 1
          pointMarkerOffsets.push({ dateIndex: B.dateIndex, offset })
        }
      }
      
      // Draw a line from the last computed tangent point to the final point.
      const last = coords[coords.length - 1]
      path += ` L ${last.x} ${last.y}`
      
      return { path, pointMarkerOffsets }
    },
    lineDashArray () {
      return `${LINE_DASH_LENGTH} ${LINE_DASH_GAP}`
    },
  }
}

const calculateArcStartAndEndPoints = (B, v1, v2, d) => {
  const v1Magnitude = Math.hypot(v1.x, v1.y)
  const v2Magnitude = Math.hypot(v2.x, v2.y)
  
  // Normalize vectors
  const v1UnitVector = { x: v1.x / v1Magnitude, y: v1.y / v1Magnitude }
  const v2UnitVector = { x: v2.x / v2Magnitude, y: v2.y / v2Magnitude }
  
  // Calculate point P: moving in opposite direction of v1
  const P = { x: B.x - v1UnitVector.x * d, y: B.y - v1UnitVector.y * d }
  // Calculate point Q: moving in direction of v2
  const Q = { x: B.x + v2UnitVector.x * d, y: B.y + v2UnitVector.y * d }
  
  return { P, Q }
}
</script>