<template>
  <div v-if="numPlotLines && dimensions?.width > 0 && dimensions?.height > 0" 
  class="plot-lines w-full h-full flex flex-col justify-between">
    <div v-for="n in (numPlotLines * 2) - 1" :key="`plot-line-${n}`" class="w-full">
      <div v-if="n % 2 === 1" class="w-full h-0 border-t border-border-normal border-opacity-90" />
        <svg v-else :width="dimensions.width - 4" :height="2" :viewBox="`0 0 ${dimensions.width} 1`">
          <path :d="`M 4 1 L ${dimensions.width} 1`" stroke="#ECEFF3" 
          stroke-width="1.5" fill="none" stroke-dasharray="0 8" stroke-linecap="round" />
        </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackgroundRenderer',
  props: {
    dimensions: {
      type: Object,
      required: true
    },
    numPlotLines: {
      type: Number,
      default: 0
    }
  }
}
</script>