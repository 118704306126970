<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M4.59961 5.94961C4.59961 5.20402 5.20402 4.59961 5.94961 4.59961H7.29961C8.04519 4.59961 8.64961 5.20402 8.64961 5.94961V7.29961C8.64961 8.04519 8.04519 8.64961 7.29961 8.64961H5.94961C5.20402 8.64961 4.59961 8.04519 4.59961 7.29961V5.94961Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.59961 12.6996C4.59961 11.954 5.20402 11.3496 5.94961 11.3496H7.29961C8.04519 11.3496 8.64961 11.954 8.64961 12.6996V14.0496C8.64961 14.7952 8.04519 15.3996 7.29961 15.3996H5.94961C5.20402 15.3996 4.59961 14.7952 4.59961 14.0496V12.6996Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.3496 5.94961C11.3496 5.20402 11.954 4.59961 12.6996 4.59961H14.0496C14.7952 4.59961 15.3996 5.20402 15.3996 5.94961V7.29961C15.3996 8.04519 14.7952 8.64961 14.0496 8.64961H12.6996C11.954 8.64961 11.3496 8.04519 11.3496 7.29961V5.94961Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.3496 12.7C11.3496 12.0044 11.954 11.4 12.6996 11.35H14.0496C14.7952 11.4 15.3996 11.9544 15.3996 12.7V14.05C15.3996 14.7956 14.7952 15.4 14.0496 15.4H12.6996C11.954 15.4 11.3496 14.7956 11.3496 14.05V12.7Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'GridGraphIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
