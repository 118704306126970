<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none">
    <path d="M8 16L16 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-dasharray="6 3"/>
  </svg>
</template>

<script>
export default {
  name: 'SecondaryMetricLineIcon',
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    }
  }
}
</script>

<style scoped></style>