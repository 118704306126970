
<template>
  <div>
    <div
      ref="groupTooltip"
      class="include-checkbox-container max-w-sm flex flex-col gap-0.5"
    >
      <BaseText
        v-for="(adName,adIndex) in tooltipText.groupTooltip"
        :key="`${adName}-${adIndex}`"
        class="text-white"
        size="xs"
      >
        {{ adName }}
      </BaseText>
    </div>
    <!-- TODO @Sam: header hover tooltip (formula & desc) -->
    <div
      ref="checkboxInclude"
      class="text-white -top-24 include-checkbox-container"
    >
      <BaseText
        size="xs"
        class="checkbox-copy"
      >
        {{ tooltipText.checkboxInclude }}
      </BaseText>
    </div>
    <div
      ref="includeTooltip"
      class="include-tooltip-container"
    >
      <BaseText
        size="xs"
        class="w-full text-white p-1.5"
      >
        Include Visualization
      </BaseText>

      <div
        class="bg-neutral-alpha-50 p-1"
        style="border-radius:4px"
      >
        <div
          v-for="graph in graphVisuals"
          :key="graph.name"
          class="flex items-center justify-between rounded-sm p-0.5 pr-2"
          :class="{'bg-neutral-alpha-25' : graphInfo.selectedGraph === graph.key}"
        >
          <div class="text-neutral-alpha-650 flex items-center">
            <component :is="graph.icon" />
            <BaseText
              size="xs"
            >
              {{ graph.name }}
            </BaseText>
          </div>
          <BaseText
            class="text-white"
            size="xs"
            type="label"
          >
            {{ graphInfo.data[graph.key].selected }}/{{ graphInfo.data[graph.key].total }}
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarGraphIcon from '../../globals/Icons/LensIcons/graphs/BarGraphIcon.vue'
import LineGraphIcon from '../../globals/Icons/LensIcons/graphs/LineGraphIcon.vue'
import GridGraphIcon from '../../globals/Icons/LensIcons/graphs/GridGraphIcon.vue'
export default {
  name: 'Columntooltips',
  components: {
    BarGraphIcon,
    LineGraphIcon,
    GridGraphIcon
  },
  props: {
    graphInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const graphVisuals = [
      {
        name: 'Bar Graph',
        icon: BarGraphIcon,
        key: 'bar'
      },
      {
        name: 'Line Graph',
        icon: LineGraphIcon,
        key: 'line'
      },
      {
        name: 'Grid View',
        icon: GridGraphIcon,
        key: 'grid'
      }
    ]
    return {
      graphVisuals,

      tooltipText: {
        checkboxInclude: 'Include in visualization',
        groupTooltip: []
      }
    }
  },
  methods: {
    updateTextCopy (key, copy) {
      this.tooltipText[key] = copy
    }
  }
}
</script>

<style scoped>
.include-tooltip-container{
  pointer-events: none;
  z-index:9999;
  position: fixed;
  padding: 4px;
  border-radius: 8px;
  background: rgba(6, 7, 16, 0.88);
  width: 186px;
  opacity: 0;
  backdrop-filter: blur(4px);
}
.include-checkbox-container{
  pointer-events: none;
  z-index:9999;
  position: fixed;
  padding: 4px;
  border-radius: 8px;
  background: rgba(6, 7, 16, 0.88);
  width: max-content;
  opacity: 0;
  backdrop-filter: blur(4px);
}
</style>
